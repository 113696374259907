import React from 'react';
import SEO from '../../components/SEO';
import Layout from '../../components/common/Layout';
import RightToBeForgottenSuccess from '../../components/from/RightToBeForgotten/RightToBeForgottenSuccess';

const RightToBeForgottenSuccessPage = () => (
  <Layout lang="de">
    <SEO
      title="Recht auf Vergessenwerden erfolgreich - Nextcloud Hosting"
      description="Deine Daten werden nun gelöscht."
    />
    <RightToBeForgottenSuccess lang="de" />
  </Layout>
);

export default RightToBeForgottenSuccessPage;
